.userImage {
    width: 120px;
    clip-path: circle();
}

.userImageInvite {
    width: 200px;
    clip-path: circle();
}


.userName {
    font-size: 20px;
    color: #fff;
}

.media {
    width: 60%;
}

.phone {
    width: 60%;
}
.white {
    color: #fff;
}